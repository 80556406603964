// Import Components
import SEO from "@americanexpress/react-seo"
import { Modal, Row, Col } from "antd"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import Header from "components/Header/Header"

// Import Store
import Store from "modules/Webinars/Webinar.store"
import Personal from "modules/Personal/UserStore"
import WebinarPrice from "components/WebinarPrice"
import CoursesStore from "modules/Courses/CoursesStore"
import PaymentsStore from "modules/Paymonts/PaymontsStore"

// Import Style
import "./webinar.scss"
import UserStore from "modules/Personal/UserStore"
import ButtonBack from "components/ButtonBack"
import locals from "store/locals"
import ym from "react-yandex-metrika"
import WebinarStore from "modules/Webinars/Webinar.store"
import dateFormat from "shared/hooks/dateFormat"
import { iframePlayers } from "shared/utils/iframePlayers"

const Webinar = () => {
	const params = useParams()
	useEffect(() => {
		Store.getWebinar(params.id)
		UserStore.getUser()
		setTimeout(() => {
			CoursesStore.getCourse(Store?.webinar?.course)
		}, 2000)
		WebinarStore.checkSingleWebinarReg(params.id)
	}, [params.id])

	useEffect(() => {
		function ChatbroLoader(chats, async) {
			async = !1 !== async
			const params = {
				embedChatsParameters: chats instanceof Array ? chats : [chats],
				lang: navigator.language || navigator.userLanguage,
				needLoadCode: "undefined" == typeof Chatbro,
				embedParamsVersion: localStorage.embedParamsVersion,
				chatbroScriptVersion: localStorage.chatbroScriptVersion,
			}

			const xhr = new XMLHttpRequest()

			xhr.withCredentials = !0
			xhr.onload = function () {
				// eslint-disable-next-line no-eval
				eval(xhr.responseText)
			}

			xhr.onerror = function () {
				console.error("Chatbro loading error")
			}

			xhr.open(
				"GET",
				"//www.chatbro.com/embed.js?" + btoa(unescape(encodeURIComponent(JSON.stringify(params)))),
				async,
			)

			xhr.send()
		}
		ChatbroLoader({ parentEncodedChatId: "58o82", chatTitle: `Чат вебинар ${params.id}` })
	}, [])

	if (locals.get("user") && !locals.get("user")?.permissions) {
		if (WebinarStore.regStatus === 0) document.location.href = "/"
	}

	const student = "https://student.modnayakontora.ru/"
	const system = 1

	const comment = `Оплата обучения: ${CoursesStore?.course?.title}`

	if (!Store.webinar && !CoursesStore.course) {
		return <></>
	}

	let viewed = locals.get("webinar_" + params.id)
	if (viewed !== 1) {
		locals.set("webinar_" + params.id, 1)
		ym("reachGoal", "webinarWatch")
	}

	return (
		<>
			<SEO title={`Вебинар: ${Store?.webinar?.name}`} />
			<Header />
			<div className="webinarPage">
				<div className="container-large no-gut">
					<Row>
						<Col xs={8} md={10} lg={4} xl={3}>
							<ButtonBack title="Назад" link="/" />
						</Col>
						{Store?.webinar?.date_start > (Date.now() / 1000).toFixed(0) && (
							<Col xs={16} md={14} lg={20} xl={21} className="webinarStart">
								Старт{" "}
								<span>
									{dateFormat(Store?.webinar?.date_start, "DD MMMM YYYY")}{" "}
									{Store?.webinar?.time_start}
								</span>
							</Col>
						)}
					</Row>
					<Row gutter={[45, 45]}>
						<Col xs={24} lg={16} xl={18}>
							<div
								className="webinar-frame"
								dangerouslySetInnerHTML={{
									__html: iframePlayers(Store?.webinar?.video_type, {
										src: Store.webinar?.webinar_stream,
										hash: Store.webinar?.video_hash,
										origin: student,
									}),
								}}
							/>
						</Col>
						<Col xs={24} lg={8} xl={6}>
							<div id="chatbro" className="webinar-video"></div>
						</Col>
					</Row>
				</div>
				<div className="container">
					{Store?.webinar?.show_prices && CoursesStore?.course?.prices ? (
						<WebinarPrice
							comment={comment}
							course={CoursesStore}
							webinar={Store}
							system={system}
							user={Personal}
						/>
					) : null}
				</div>
			</div>
			<Modal
				centered
				open={PaymentsStore.modalConfirm.status}
				onCancel={(e) => PaymentsStore.closeModalConfirm(e)}
				closable={false}
				footer={false}
				maskClosable={false}
				width={320}
				className="modalContainer"
			>
				<div className="modalContainerColumn">
					<button
						className="btnModalOpen"
						onClick={(e) => {
							PaymentsStore.newPayment(
								e,
								PaymentsStore.modalConfirm.comment,
								PaymentsStore.modalConfirm.course,
								PaymentsStore.modalConfirm.system,
								PaymentsStore.modalConfirm.price,
								PaymentsStore.modalConfirm.user,
							)
						}}
					>
						Перейти к оплате
					</button>
					<button className="btnModalClose" onClick={(e) => PaymentsStore.closeModalConfirm(e)}>
						Отмена
					</button>
				</div>
			</Modal>
		</>
	)
}

export default observer(Webinar)
