// Import Components
import { useEffect } from "react"
import { observer } from "mobx-react-lite"
import locals from "../../store/locals"
// import ForgotPass from "../../components/ForgotPass"
import { useLocation } from "react-router-dom"

// Import Store
import AuthStore from "./AuthStore"
import WebinarRegStore from "../../pages/WebinarReg/WebinarRegStore"
import RegistrationStore from "../../pages/RegistrationPage/RegistrationStore"

// Import Antd
import { Row, Col, Input, Space, Button, Modal, Select } from "antd"
import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined } from "@ant-design/icons"

// Import PhoneInput
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import ru from "react-phone-input-2/lang/ru.json"

import { useReferences } from "shared/hooks"
import { parseReferencesInstallments } from "shared/utils"

const Auth = observer(({ courseID = 0, amount = 0, systemID = 1, webinarID = 0 }) => {
	const { references, isLoading } = useReferences()

	useEffect(() => {
		const intervalId = setInterval(() => {
			if (locals.get("codeSend")) {
				AuthStore.checkRemainTime()
			}
		}, 1000)

		if (WebinarRegStore.successRegistration && webinarID !== 0) {
			WebinarRegStore.getWebinar(Number(webinarID))
			WebinarRegStore.makeWebinar(Number(webinarID))
		}
		return () => {
			clearInterval(intervalId)
		}
	}, [webinarID])

	const installments = parseReferencesInstallments(references)

	const { hash } = useLocation()
	if (hash === "#password") AuthStore.authByPassword = true

	return (
		<>
			<Modal
				title={false}
				open={AuthStore.waitForCode}
				footer={false}
				maskClosable={false}
				onCancel={(_) => {
					AuthStore.clearCode()
					AuthStore.waitForCode = false
				}}
				width={300}
			>
				<form
					onSubmit={(e) => {
						AuthStore.LogInByCode(e, courseID, amount, systemID, webinarID)
					}}
				>
					<Space direction="vertical" align="center">
						<p>Код для авторизации поступит в WhatsApp.</p>
						<Input
							size="large"
							value={AuthStore.user.code}
							onChange={(e) => {
								let value = e.target.value.replace(/[^0-9.]/, "")
								AuthStore.onChange("code", value)
							}}
							placeholder="Код"
							maxLength={4}
							required
						/>
						<Button size="large" type="primary" htmlType="submit" block>
							Войти
						</Button>
						<div className="textDivider">или получить код повторно</div>
						<Button
							disabled={AuthStore.codeTimeout}
							size="large"
							type="primary"
							block
							onClick={(e) => {
								AuthStore.user.code = ""

								AuthStore.LogInByCode(e)
							}}
						>
							{AuthStore.codeTimeout ? (
								<>
									через {AuthStore.timer.minutes}:{AuthStore.timer.seconds}
								</>
							) : (
								<>Получить код повторно</>
							)}
						</Button>
					</Space>
				</form>
			</Modal>
			{AuthStore.hiddenForgotPass ? (
				<Row justify="center" align="middle">
					<Col xs={24} className="auth-form-inner">
						<form
							onSubmit={(e) => {
								if (AuthStore.authByPassword) {
									AuthStore.LogIn(e, courseID, amount, systemID, webinarID)
								} else {
									AuthStore.LogInByCode(e, courseID, amount, systemID, webinarID)
								}
							}}
						>
							<Space direction="vertical" size="middle">
								{AuthStore.authByPassword === true ? (
									<>
										<PhoneInput
											localization={ru}
											preferredCountries={["ru"]}
											inputProps={{
												required: true,
											}}
											country={"ru"}
											value={AuthStore.user.phone}
											onChange={(e) => {
												AuthStore.onChange("phone", e)
											}}
										/>
										{systemID === 5 && (
											<Select
												style={{ width: "100%" }}
												size="large"
												value={RegistrationStore.installments}
												options={installments}
												onChange={(event) => RegistrationStore.onChangeInstallments(event)}
												placeholder="Срок рассрочки"
												loading={isLoading}
												required
											/>
										)}
										<Input.Password
											size="large"
											placeholder="Пароль"
											value={AuthStore.user.password}
											onChange={(e) => AuthStore.onChange("password", e.target.value)}
											prefix={<LockOutlined />}
											iconRender={(visible) =>
												visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
											}
											required
										/>

										<Button
											size="large"
											type="primary"
											htmlType="submit"
											block
											disabled={AuthStore.statusResponse === true}
										>
											Войти
										</Button>
									</>
								) : (
									<>
										<PhoneInput
											localization={ru}
											preferredCountries={["ru"]}
											inputProps={{
												required: true,
											}}
											country={"ru"}
											value={AuthStore.user.phone}
											onChange={(e) => {
												AuthStore.onChange("phone", e)
											}}
										/>
										{systemID === 5 && (
											<Select
												style={{ width: "100%" }}
												size="large"
												value={RegistrationStore.installments}
												onChange={(event) => RegistrationStore.onChangeInstallments(event)}
												placeholder="Выберите срок рассрочки"
												required
												options={installments}
												loading={isLoading}
											/>
										)}
										<Button
											size="large"
											type="primary"
											htmlType="submit"
											block
											disabled={AuthStore.waitForCode === true}
										>
											Получить код
										</Button>
									</>
								)}
								{AuthStore.authByPassword === false && (
									<Button
										size="large"
										type="primary"
										block
										disabled={AuthStore.waitForResponse === true}
										onClick={(e) => AuthStore.ForgotPass(e)}
									>
										Получить пароль на e-mail
									</Button>
								)}
								{AuthStore.hiddenForgotPass === true ? (
									<Button
										type="link"
										onClick={(_) => {
											AuthStore.authByPassword = AuthStore.authByPassword === false
										}}
									>
										{AuthStore.authByPassword ? "Войти по номеру телефона" : "Войти по паролю"}
									</Button>
								) : null}
								{AuthStore.successForgotPass === true && (
									<div className="forgotForm-text">
										Если вы зарегистрированы в системе, то на указанный вами при регистрации адрес
										e-mail был отправлен новый пароль.
										<br />
										После получения воспользуйтесь авторизацией по паролю.
									</div>
								)}
								<div className="textDivider">Код приходит на WhatsApp</div>
							</Space>
						</form>
					</Col>
				</Row>
			) : null}
		</>
	)
})

export default Auth
