import axios from "../../axios"
import { useEffect, useState } from "react"

export const useReferences = () => {
	const [references, setReferences] = useState({})
	const [isLoading, setIsLoading] = useState(false)
	const getReferences = async () => {
		try {
			setIsLoading(true)
			const { data } = await axios.get("/references/get")
			setReferences(data)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.error("getReferences error:", error)
		}
	}
	useEffect(() => {
		getReferences()
	}, [])

	return {
		references,
		isLoading,
	}
}
