// Import Library
import { useState, useEffect, useRef } from "react"
import { observer } from "mobx-react-lite"
import classnames from "classnames"

// Import Framework

// Import Components

// Import Store

// Import Style
import styles from "./select.module.scss"

// Import Hooks

export function useOnClickOutside(ref, handler) {
	useEffect(() => {
		const listener = (event) => {
			if (!ref.current || ref.current.contains(event.target)) {
				return
			}
			handler(event)
		}
		document.addEventListener("mousedown", listener)
		document.addEventListener("touchstart", listener)
		return () => {
			document.removeEventListener("mousedown", listener)
			document.removeEventListener("touchstart", listener)
		}
	}, [ref, handler])
}

export const ArrowDownIcon = () => {
	return (
		<svg viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16.5303 1.53033C16.8232 1.23744 16.8232 0.762563 16.5303 0.46967C16.2374 0.176777 15.7626 0.176777 15.4697 0.46967L16.5303 1.53033ZM8.5 8.5L7.96967 9.03033C8.26256 9.32322 8.73744 9.32322 9.03033 9.03033L8.5 8.5ZM1.53033 0.469671C1.23744 0.176777 0.762562 0.176777 0.46967 0.469671C0.176777 0.762564 0.176777 1.23744 0.46967 1.53033L1.53033 0.469671ZM15.4697 0.46967L7.96967 7.96967L9.03033 9.03033L16.5303 1.53033L15.4697 0.46967ZM9.03033 7.96967L1.53033 0.469671L0.46967 1.53033L7.96967 9.03033L9.03033 7.96967Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export const Select = observer(({ value, onChange, isOpen, placeholder, defaultOption, options }) => {
	const [selectedOption, setSelectedOption] = useState({})
	options = options || []
	defaultOption = defaultOption || null
	placeholder = placeholder || ""
	isOpen = isOpen || false

	const [open, setOpen] = useState(isOpen)
	const ref = useRef()

	const handlerOpen = (event) => {
		event.preventDefault()
		if (open) {
			setOpen(false)
		} else {
			setOpen(true)
		}
	}

	const selectActive = (id) => {
		const option = options.find((item) => item.id === id)
		setSelectedOption({ id: option.id, name: option.name })
	}

	const handlerOption = (event) => {
		event.preventDefault()
		onChange(event.currentTarget.id)
		selectActive(event.currentTarget.id)
		setOpen(false)
	}

	useOnClickOutside(ref, () => {
		setOpen(false)
	})

	const classOpen = classnames(styles.selectList, { [styles.selectListActive]: open })
	const classButton = classnames(styles.selectButton, { [styles.selectButtonActive]: open })

	return (
		<div className={styles.select} ref={ref}>
			<button className={classButton} type="button" onClick={handlerOpen}>
				<div className={styles.selectButtonLabel}>
					{/* // TODO: Продумать placeholder и defaultOptions */}
					{selectedOption.name ? selectedOption.name : placeholder}
				</div>
				<span className={styles.selectButtonArrow}>
					<ArrowDownIcon />
				</span>
			</button>
			{options.length ? (
				<div className={classOpen}>
					{options.map((item) => (
						<button key={item.id} id={item.id} className={styles.selectListItem} onClick={handlerOption}>
							<div className={styles.selectListItemStatus}>
								{item.active === true && <div className={styles.selectListItemStatusActive}></div>}
							</div>

							<span className={styles.selectListItemLabel}>{item.name}</span>
						</button>
					))}
				</div>
			) : null}
		</div>
	)
})
