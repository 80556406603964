import SEO from "@americanexpress/react-seo"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

const RedirectPage = () => {
	let [searchParams] = useSearchParams()
	useEffect(() => {
		const url = searchParams.get("url")
		if (url) {
			window.location.replace(searchParams.get("url"))
		}
	}, [searchParams])
	return (
		<>
			<SEO title="Переадресация" />
		</>
	)
}

export default observer(RedirectPage)
