const validateReferencesInstallments = (references) => {
	if (
		!references ||
		typeof references !== "object" ||
		Array.isArray(references) ||
		!Object.prototype.hasOwnProperty.call(references, "installments")
	) {
		return false
	}
	return true
}

export const parseReferencesInstallments = (references) => {
	if (!validateReferencesInstallments(references)) return []
	return Object.entries(references.installments).map(([key, value]) => ({ id: key, name: value.title }))
}
