import React, { useEffect } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import HomePage from "../pages/HomePage/HomePage"
import CoursesPage from "../pages/CoursesPage/CoursesPage"
import CoursesDetailPage from "../pages/CoursesDetailPage/CoursesDetailPage"
import PersonalPage from "../pages/PersonalPage/PersonalPage"
import PageNotFound from "../pages/NotFound/NotFound"
// import RegistrationPage from "../pages/RegistrationPage/RegistrationPage"
import SuccessPage from "../pages/SuccessPage/SuccessPage"
import FailPage from "../pages/FailPage/FailPage"
import Webinar from "../pages/Webinar/"
import WebinarReg from "../pages/WebinarReg/WebinarRegPage"
import PaymentsPage from "../pages/PaymentsPage"

import Middleware from "../Middleware"
import RegCoursePage from "../pages/RegCoursePage/RegCoursePage"
import { Demo } from "pages/Demo/Demo"
import RedirectPage from "pages/RedirectPage/RedirectPage"
const RoutesPrivate = () => {
	useEffect(() => {
		Middleware.checkToken()
		setInterval(() => {
			Middleware.checkToken()
		}, 30000)
	})
	return (
		<>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/payments" element={<PaymentsPage />} />
				<Route path="/courses" element={<CoursesPage />} />
				<Route path="/courses/:id" element={<CoursesDetailPage />} />
				<Route path="/personal" element={<PersonalPage />} />
				<Route path="/success" element={<SuccessPage />} />
				<Route path="/fail" element={<FailPage />} />
				<Route path="/404" element={<PageNotFound />} />
				<Route path="demo" element={<Demo />} />
				<Route path="*" element={<Navigate to="/404" replace />} />

				<Route path="/webinar/:id" element={<Webinar />} />

				<Route path="/webinarreg/:id" element={<WebinarReg />} />
				{/* <Route path="/reg/:id/:amount/:system" element={<RegistrationPage />} /> */}
				<Route path="/reg/:id/:amount/:system" element={<RegCoursePage />} />
				<Route path="/redirect" element={<RedirectPage />} />
				{/* <Route path="/reg-course/:id/:amount/:system" element={<RegCoursePage />} /> */}
			</Routes>
		</>
	)
}

export default RoutesPrivate
