const isEmpty = (obj) => [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length

const parseObjectToParams = (obj) => {
	const keys = Object.keys(obj)
	const query = []
	for (const key of keys) {
		if (!isEmpty(obj[key])) {
			query.push(`${key}=${obj[key]}`)
		}
	}
	return query.join("&")
}

const mapIframePlayers = {
	youtube: ({ src, original }) =>
		`<iframe
			src="https://www.youtube.com/embed/${src}?enablejsapi=1&origin=${original}&autoplay=0&cc_load_policy=0&cc_lang_pref=ru&iv_load_policy=3&loop=0&modestbranding=1&rel=0&fs=0&playsinline=1&autohide=2&theme=dark&color=red&controls=1&"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowfullscreen=""
		/>`,
	vimeo_video: ({ src }) => {
		return `
        <iframe
            src="https://player.vimeo.com/video/${src}"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        />`
	},
	vimeo_event: ({ src }) => {
		return `
        <iframe 
            src="https://vimeo.com/event/${src}/embed" 
            frameborder="0" 
            allow="autoplay; 
            fullscreen; 
            picture-in-picture" 
            allowfullscreen 
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
        />`
	},
	vk: ({ src, hash }) => {
		const array = src.split("video")
		const [oid, id] = array[array.length - 1].split("_")
		return `
        <iframe 
            src="https://vk.com/video_ext.php?${parseObjectToParams({ oid, id, hash, hd: 4 })}" 
            allow="autoplay; encrypted-media; 
            fullscreen; picture-in-picture;" 
            frameborder="0" 
            allowfullscreen 
        />`
	},
}

export const iframePlayers = (type, ...args) => {
	const player = mapIframePlayers[type] ?? null
	if (!player) return ""
	return player(...args)
}
