import { observer } from "mobx-react-lite"
import { Space, Button, Divider, Select } from "antd"
import { YMaps, Map } from "@pbe/react-yandex-maps"
import RegistrationStore from "../../pages/RegistrationPage/RegistrationStore"
import {
	InfoCircleOutlined,
	// 	MailOutlined,
	// 	UserOutlined,
	// 	InstagramOutlined,
	// 	EnvironmentOutlined,
} from "@ant-design/icons"
import "./Registration.scss"
import { Input } from "./ui"

// Import PhoneInput
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import ru from "react-phone-input-2/lang/ru.json"
import { useReferences } from "shared/hooks"
import { parseReferencesInstallments } from "shared/utils"
const mapState = {
	center: [55.76, 37.64],
	zoom: 10,
	controls: [],
}

const Registration = ({ courseID = 0, amount = 0, systemID = 1, webinarID = 0 }) => {
	const { references, isLoading } = useReferences()
	const installments = parseReferencesInstallments(references)

	return (
		<>
			<form
				onSubmit={(e) => RegistrationStore.SignUp(e, courseID, amount, systemID, webinarID)}
				className="Registration__container"
			>
				<div className="Registration__container-info">
					<div className="Registration__container-info--icon">
						<InfoCircleOutlined />
					</div>
					<div className="Registration__container-info--text">
						Пожалуйста, указывайте актуальные данные, чтобы избежать проблем со входом в личный кабинет.
					</div>
				</div>
				<Divider className="Registration__container-divider" />
				<Space direction="vertical" size="middle">
					<Input
						placeholder="E-mail"
						type="email"
						onChange={(e) => {
							RegistrationStore.onChangeReg("email", e.target.value)
						}}
						value={RegistrationStore.user.email}
						required={true}
					/>

					<PhoneInput
						containerClass="phoneContainer"
						inputClass="phoneInput"
						buttonClass="phoneButton"
						localization={ru}
						preferredCountries={["ru"]}
						inputProps={{
							required: true,
						}}
						country={"ru"}
						value={RegistrationStore.user.phone}
						onChange={(e) => {
							RegistrationStore.onChangeReg("phone", e)
						}}
					/>

					<Input
						placeholder="Имя"
						onChange={(e) => {
							RegistrationStore.onChangeReg("name", e.target.value)
						}}
						value={RegistrationStore.user.name}
						required={true}
					/>

					<Input
						placeholder="Фамилия"
						onChange={(e) => {
							RegistrationStore.onChangeReg("surname", e.target.value)
						}}
						value={RegistrationStore.user.surname}
						required={true}
					/>

					<Input
						placeholder="Страна"
						onChange={(e) => {
							RegistrationStore.onChangeReg("country", e.target.value)
						}}
						value={RegistrationStore.user.country}
						required={true}
					/>

					<Input
						placeholder="Город"
						onChange={(e) => {
							RegistrationStore.onChangeReg("city", e.target.value)
						}}
						value={RegistrationStore.user.city}
						required={true}
					/>

					<Input
						placeholder="ВК"
						onChange={(e) => {
							RegistrationStore.onChangeReg("vk", e.target.value)
						}}
						value={RegistrationStore.user.vk}
						required={true}
					/>

					<Input
						placeholder="Инстаграм"
						onChange={(e) => {
							RegistrationStore.onChangeReg("instagram", e.target.value)
						}}
						value={RegistrationStore.user.instagram}
						required={true}
					/>

					{systemID === 5 && (
						<Select
							style={{ width: "100%" }}
							size="large"
							value={RegistrationStore.installments}
							options={installments}
							onChange={(event) => RegistrationStore.onChangeInstallments(event)}
							placeholder="Срок рассрочки"
							required
							loading={isLoading}
						/>
					)}

					<Button type="primary" htmlType="submit" disabled={RegistrationStore.buttonDisabled} block>
						Зарегистрироваться
					</Button>

					<div className="Registration__container-reg">
						Нажимая кнопку "Зарегистрироваться" вы соглашаетесь с политикой конфиденциальности сайта.
					</div>
				</Space>
				<div style={{ display: "none" }}>
					<YMaps query={{ load: "package.full" }}>
						<Map
							state={mapState}
							onLoad={RegistrationStore.onYmapsLoad}
							instanceRef={(map) => (RegistrationStore.map = map)}
						/>
					</YMaps>
				</div>
			</form>
		</>
	)
}

export default observer(Registration)
